html {
  font-size: 62.5%;
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100%;
  font-size: 1.6rem;
  font-family: Helvetica, Arial, sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

#root {
  height: 100%;
}

/* Layout */
/* .grid-container {
  display: grid;
  grid-template-areas:
    'header'
    'main'
    'footer';
  grid-template-columns: 1fr;
  grid-template-rows: 5rem 1fr 5rem;
  height: 100%;
} */

header {
  grid-area: header;
  background-color: rgb(6, 31, 35);
  padding: 0.3rem;

}

main {
  padding: 1rem;
  position: relative;
  padding-bottom: 6rem;
}

/* main:nth-child(2) {
  background-color: rgb(222, 239, 241, 50%);
  ;
} */

footer {
  height: 5rem;
  width: 100%;
  left: 0;
  position: fixed;
  bottom: 0;
  background-color: #203040;
  color: #ffffff;
}

/* Common */
h1 {
  font-size: 1.8rem;
  /* padding: 1rem 0; */
}

h2 {
  font-size: 1.6rem;
  padding: 1rem 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

a {
  text-decoration: none;

}



a:hover {
  color: #ff8000;
}

/* Header */
header a {
  color: #ffffff;
  padding: 1rem;
}

a.brand {
  color: #ffffff;
  font-size: 3rem;
  font-weight: bold;
  padding-left: 2rem;
}


.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

}

.row-left {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
}

.row-image {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0.6rem;
}


.row-mini-img {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 0.6rem;
}

.row-mini-img>p {
  color: rgb(45, 33, 33);
  font-weight: 100;
  font-size: medium;
}

.col-2 {
  flex: 2 1 50rem;
  margin: 2rem;
}

.col-1 {
  flex: 1 1 30rem;
  margin: 2rem;
}


.col-1>ul {
  margin: 3rem 0rem;
  font-size: larger;
}

.product-title {
  font-size: larger;
  font-weight: bold;
}

ul>li {
  margin: 1.5rem 0rem;
}

.col-3 {
  flex: 1.5;
}



.col-4 {
  flex: 1;

}

.row.center {
  justify-content: center;
}

/* Image */
img {
  border-radius: 0.5rem;
}

img.medium {
  max-width: 29rem;
  width: 100%;
}

img.medium:hover {
  cursor: pointer;
}


.large {
  max-width: 45rem;
}

img.small {
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  object-position: 100% 0;


}

img.cover {
  max-width: 35rem;
  opacity: 80%;
}

.profile-pic-mini {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin: 0rem 1rem;
  object-fit: cover;
}

.cover-wrapper {
  margin: 1rem;
  position: relative;
  cursor: pointer;
}

.cover-name {
  position: absolute;
  color: rgb(255, 255, 255);
  bottom: 6rem;
  font-size: 4rem;
  font-weight: 800;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

/* Card */
.card {
  border: 0.1rem #c0c0c0 solid;
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  margin: 1rem;
}

.card-body {
  padding: 0.5rem 1rem 1.5rem;
  background-color: rgb(234, 240, 239);
  border-radius: 1rem;
  margin: 1rem;
}



.card-body>* {
  margin-bottom: 0.5rem;
}

.card-body h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.price {
  font-size: 1.8rem;
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: rgb(243, 34, 34);
  margin-bottom: 1rem;
}

/* Rating */
.rating span {
  color: #f0c040;
  margin: 0.1rem;
}

/* .rating span:last-child {
  color: black;
} */

/* InStock or Not */
.success {
  color: rgb(46, 179, 46);
}

.error {
  color: red;
}

/* Add to cart */
.badge {
  border-radius: 50%;
  background-color: red;
  padding: 0.2rem 0.6rem;
  margin-left: 0.3rem;
}

.add-cart,
.checkout-btn,
.order-btn {
  background-color: #111b22;
  width: 100%;
  cursor: pointer;
  padding: 1rem;
  border-radius: 1rem;
  color: white;
  font-weight: 500;
  margin-top: 1rem;
  /* border: 0.1rem grey solid; */

}

/* Action Button Admin Animation */
.action-btn {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  padding: 0.5rem 1.4rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  color: rgb(10, 10, 10);
  font-weight: 500;
  font-size: 1.5rem;
  z-index: 1;
  outline: none;


}

.action-btn:hover {
  cursor: pointer;
  animation: jelly 0.5s;
}

@keyframes jelly {

  0%,
  100% {
    transform: scale(1, 1);
  }

  25% {
    transform: scale(0.9, 1.1);
  }

  50% {
    transform: scale(1.1, 0.9);
  }

  75% {
    transform: scale(0.95, 1.05);
  }
}


/* End of Action Button Admin Animation */

.edit-btn {
  background-color: rgb(109, 227, 131);
}

.delete-btn {
  background-color: rgb(211, 77, 77);
  color: white;
}

.fa-trash,
.fa-pencil {
  margin-left: 0.5rem;
}

.fa-smile-o,
.fa-commenting-o {
  margin-left: 0.8rem;
  font-size: 1.5rem;
}

/* .fa-smile-o {
  color: rgb(242, 218, 4);
} */



.add-cart:hover {
  border: 0.1rem #404040 solid;
}

/* Form */
input {
  display: block;
  padding: 1rem;

}

input:hover {
  border: 0.2rem green solid;
}

form {
  max-width: 70rem;
  margin: auto;
}



.form>div {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

form.review-form {
  max-width: 100%;
  margin: 0;
}

form.review-form>div {
  display: block;
  margin: 2rem 0rem;
}

form.review-form>div>label {
  margin: 2rem 0rem;
}

/* form.review-form>div>label {

  margin: 8rem 0rem;
} */

form.review-form>div>textarea {
  display: block;
  width: 100%;
  font-size: 1.5rem;
  border-radius: 0.5rem;
}

form.review-form>div>select {
  display: block;
  width: 100%;
  padding: 0.6rem;
  margin: 0.5rem 0rem;
}


.review-form textarea {
  margin: 0.5rem 0rem;
}

textarea {
  display: block;
}

label {
  margin: 1rem 0;
}

.sign-in {
  margin: 1rem 0;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #f0c040;
  cursor: pointer;
}

.testdropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown,
.admin-dropdown {
  display: inline-block;
  position: relative;
  z-index: 2;

}

.dropdown-content,
.admin-dropdown-content {
  display: none;
  position: absolute;
  padding: 0.8rem 0;
  right: 0;
  margin-top: 0rem;
  min-width: 12rem;
  background-color: #2b2c43;
  border-radius: 1rem;
}

.dropdown:hover .dropdown-content {
  display: block;

}

.admin-dropdown:hover .admin-dropdown-content {
  display: block;
}

.fa-caret-down,
.fa-shopping-cart,
.fa-user-o {
  margin: 0 0.5rem;
}



/* Shipping Screen */

.checkout-steps {
  margin-bottom: 1rem;
}

.checkout-steps>div {
  flex: 1;
  margin: 0.5rem;
  border-top: 0.5rem #c0c0c0 solid;
  font-weight: bold;
  color: #c0c0c0;
}

.checkout-steps>div.active {
  border-top-color: #f0d124;
  color: #f0d124;
  /* min-width: 25%; */
}

.payment-row {
  display: flex;
  align-items: center;
}

.placeorder-row {
  display: flex;
  /* overflow: hidden; */
  align-items: flex-start;

}



input[type="radio"] {
  width: 2rem;
  height: 2rem;
}

.placeorder-body div {
  margin: 1rem 0rem;
  padding-left: 1rem;
}



.placeorder-body {
  padding: 0.5rem 1rem 1.5rem;
  background-color: rgba(186, 185, 173, 0.108);
  border-radius: 1rem;
  margin: 1rem;
}

.order-summary-row {
  margin: 1rem 1rem 1rem 2rem;
  padding: 2rem;
  background-color: rgba(186, 185, 173, 0.108);
  border-radius: 2rem;
}

.order-summary-row>div {
  margin: 1rem;
}

.password-field {
  position: relative;
}

.password-field input {
  box-sizing: border-box;
  width: 100%;

}

.eye-icon {
  font-size: 1.8rem;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);

}

/* Profile pic */
.profile-pic-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-pic {
  width: 20rem;
  height: 20rem;
  object-fit: cover;
  border-radius: 50%;
  border: 0.4rem rgb(61, 212, 91) solid;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.profile-pic-wrapper>:first-child {
  position: relative;
  /* background-color: red; */
}

.profile-pic-wrapper>:nth-child(2) {
  /* background-color: gold; */
  margin-top: 0.8rem;
  font-size: 2rem;
  font-weight: 600;
  /* background-color: red; */
}

.input-file-profile {
  background-color: green;
  width: 1.7rem;
  position: absolute;
  right: 0.4rem;
  bottom: 0.3rem;
  z-index: 2;
  opacity: 0%;
  cursor: pointer;
}

.fa-pencil-square {
  color: rgb(31, 67, 176);
  position: absolute;
  font-size: 4.2rem;
  right: 0.4rem;
  bottom: 0.2rem;
  border-radius: 50%;
  cursor: pointer;
  /* z-index: -1; */
}




.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
}



.sidebar-btn {
  background-color: #203040;
  border: none;
  margin: 0.5rem;
}

.sidebar-btn:hover {
  cursor: pointer;
}

.sidebar-btn i {
  color: white;
  font-size: 2rem;
}

.sidebar {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(10, 35, 45);
  padding: 1.5rem;
  display: none;
  color: white;
  z-index: 2;

}



.active-sidebar,
div.categories {
  display: block;
}

.categories ul li {
  margin: 0.5rem 0;
}

.search-icon {
  color: black;
  /* background-color: rgb(223, 196, 46); */
  padding: 1rem;
  border-radius: 20%;
}

.search-btn:hover {

  background-color: rgb(36, 36, 182);
}

.search-btn:hover .search-icon {
  color: white;

}

.search-box input {
  margin: 0.7rem;
  padding: 0.7rem;
}

.search-btn {
  color: black;
  background-color: rgb(223, 196, 46);
  border-radius: 20%;
  padding: 0;
  border: none;

}

.search-categories {
  flex: 1;
  margin-left: 1rem;
  height: 60rem;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  /* padding: 1rem; */
  /* border: 0.4rem solid rgb(175, 52, 52); */

}

.search-categories::-webkit-scrollbar {
  display: none;
}





.search-categories>div {
  padding: 0 1rem;
}

.search-products {
  flex: 5;
  margin-left: 5rem;
  margin-top: 1rem;
  padding: 0.8rem;
  background-color: rgb(222, 239, 241, 50%);
  border-radius: 2rem;
  height: 62rem;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.count-result {
  margin: 0.5rem 0 0.5rem 2rem;
  padding: 0.5rem;
  font-size: 1.8rem;
}

.search-products::-webkit-scrollbar {
  display: none;
}

.search-text {
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bold-text {
  font-weight: bold;
}

/* Filter Category */

.filter-search {
  background-color: rgb(94, 170, 218);
  color: #ffffff;
  padding: 1rem 1rem;
  border-radius: 0.6rem;

}

.category {
  padding: 0rem;
}

.category>h1 {
  color: rgb(39, 135, 117);
  font-weight: 600;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.ul-category {
  font-size: 1.6rem;
  color: rgb(39, 135, 117);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.filter-img-color {
  width: 2rem;
  height: 2rem;
}

a .row.category {
  padding-right: 0.5rem;
  border-bottom: 0.1rem black solid;
}

.fa-plus-square-o,
.fa-minus-square-o {
  font-size: 1.9rem;
  color: rgb(94, 170, 218);

}

.fa-plus-square-o,
.fa-minus-square-o:hover {
  cursor: pointer;
}

/* */


/* Select */


.select>span {
  margin-right: 1rem;
  font-size: medium;
  font-style: italic;
}

.select>select {
  padding: 0.5rem;

}

.product-table,
td,
th {
  border: 1px solid;
  text-align: center;
  font-size: 1.5rem;
}

td {
  padding: 0.7rem;
}

th {
  padding: 1.2rem;
  background-color: rgb(177, 220, 228);
}

tr {
  width: 2rem;
}

.product-table {
  border: 1px solid;
  width: 80%;
  margin: auto;
  border-collapse: collapse;

}

.products-h1 {
  text-align: center;
}

.row-create {
  display: flex;
  justify-content: space-around;
  margin: 1rem;
}

.mini-color {
  width: 3rem;
  height: 3rem;
}

.mini-color:hover {
  cursor: pointer;
}

.fa-plus-circle {
  font-size: 1.5rem;
  margin: 0 1rem;
}

#isAdmin {
  width: 1.5rem;
  height: 1.5rem;
}

/* product list */

.admin-product-watchlist {
  background-color: rgb(255, 255, 255);
  padding: 0 2rem;
  justify-content: space-around;
  margin: 1rem 15rem 2rem 15rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}



.admin-product-watchlist h2 {
  margin: 0;
}

.admin-product-watchlist p {
  font-size: 3rem;
  font-weight: lighter;
  margin: 0;
  color: rgb(52, 62, 106);
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.login-review {

  background-color: rgb(35, 105, 190);
  color: white;
  width: 50%;
  padding: 1rem;
  text-align: center;
  border-radius: 1rem;
  cursor: pointer;
}

.message-review {
  border-radius: 0.4rem;
  padding: 1.5rem;
  background-color: rgb(147, 191, 245);
}


.review-section {

  margin: 2rem 4rem 4rem;
}

.review-section>div {
  background-color: rgb(255, 255, 255);
}

.review-section>div>h1 {
  font-size: 3rem;
}

.review-comment {
  border: 0.2rem rgb(224, 220, 220) solid;
  padding: 1rem;
  border-radius: 0.8rem;
  margin: 0.8rem 0rem;
}

.review-comment div {
  padding: 1rem 0rem;

}

.review-comment p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-style: italic;
  font-weight: 400;

}

.num-review {
  margin-left: 1rem;
  font-family: cursive;
}

/* Product Name in CartScreen */

.cart-list div:nth-child(2) {
  width: 25rem;
  color: red;
}

.cart-list div:nth-child(3) {
  width: 3.5rem;
}

.cart-list div:nth-child(4) {
  color: red;
  width: 3rem;
  font-weight: 500;
}

.cart-list div:last-child {

  width: 7rem;
}

.select-item-tag {
  min-width: 3.5rem;
}

.admin-user-watchlist {
  justify-content: space-evenly;
}

/* Loading animation */




.loader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 32px;
  margin: 20px;
}

.loader span {
  width: 5px;
  height: 20px;
  background-color: #28d7d7;
}

.loader span:nth-of-type(1) {
  animation: grow 1s -0.45s ease-in-out infinite;
}

.loader span:nth-of-type(2) {
  animation: grow 1s -0.3s ease-in-out infinite;
}

.loader span:nth-of-type(3) {
  animation: grow 1s -0.15s ease-in-out infinite;
}

.loader span:nth-of-type(4) {
  animation: grow 1s ease-in-out infinite;
}

@keyframes grow {

  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2);
  }
}










@media only screen and (max-width: 760px) {

  header.row {
    flex-direction: column;
  }

  .brand-wrapper {
    margin-top: 0.8rem;
  }

  .search-box form input {
    width: 80%;
  }

  .search-box form>div:first-child {
    justify-content: center;
  }

  .search-box {
    width: 100%;
  }

  .testdropdown {
    font-size: 1.5rem;
  }

  .badge {
    font-size: 1.2rem;
  }

  /* Search Screen */
  .placeorder-row {
    display: block;
  }

  .search-categories {
    display: block;
    height: 100%;
  }

  .search-products {
    margin: 2rem;
  }

  /* Cart Screen */
  .row-image {
    display: block;
  }

  .row-image .col-4 {
    margin: 2rem 0;
    padding: 1rem;
    border-radius: 0.5rem;
  }

  /* Product Screen */
  .large {
    max-width: 100%;
  }

  .product-ul .row-image {
    display: flex;
  }

  .select-item-tag {
    width: 2rem;
    margin-left: 0.2rem;
  }

  .cart-list div:nth-child(3),
  div:nth-child(4),
  div:nth-child(5) {
    margin-top: 2.5rem;
    margin-left: 0.5rem;
  }

  .cart-list div:nth-child(4) {
    color: red;
    font-weight: bold;
    font-size: Verdana, Geneva, Tahoma, sans-serif;
  }

  .cart-list div:nth-child(5) button {
    font-size: 1.5rem;
  }

  /* admin productlist */
  .admin-product-watchlist {
    flex-wrap: nowrap;
    justify-content: space-around;
    margin: 0;
    text-align: center;
    margin-bottom: 1rem;
  }


}

@media only screen and (min-width: 760px) and (max-width: 1300px) {

  .brand-wrapper {
    margin-top: 0.8rem;
  }


  /* Search Screen */
  .placeorder-row {
    display: block;
  }

  .search-categories {
    display: block;
    height: 100%;
  }

  .search-products {
    margin: 2rem;
  }

  /* Cart Screen */
  .row-image {
    display: block;
    width: 90%;
    margin: auto;

  }

  .row-image .col-4 {
    margin: 2rem 0;
    padding: 1rem;
    border-radius: 0.5rem;
  }

  /* Product Screen */
  .col-2.row-image {
    display: flex;
    flex-wrap: nowrap;
  }

  .large-wrapper {
    max-width: 50%;

  }

  .large {
    max-width: 100%;
  }

  .product-ul .row-image {
    display: flex;
  }

  .select-item-tag {
    width: 2rem;
    margin-left: 0.2rem;
  }

  .cart-list div:nth-child(3),
  div:nth-child(4),
  div:nth-child(5) {
    margin-top: 2.5rem;
    margin-left: 0.5rem;
  }

  .cart-list div:nth-child(4) {
    color: red;
    font-weight: bold;
    font-size: Verdana, Geneva, Tahoma, sans-serif;
  }

  .cart-list div:nth-child(5) button {
    font-size: 1.5rem;
  }

  /* admin productlist */
  .admin-product-watchlist {
    flex-wrap: nowrap;
    justify-content: space-around;
    margin: 0;
    text-align: center;
    margin-bottom: 1rem;
  }
}